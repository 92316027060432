$mi_muro-blue-modals: #3FA7E1;


.blog-main-title {
  font-size: 1.4em;
  font-family: Nutmeg-Bold, serif;
  vertical-align: middle;
  color: #606469;
}

.blog-label-dark {
  color: #585c60;
}

.label-bottom {
  font-family: "Nutmeg-Bold", serif;
  vertical-align: bottom;
}

.blog-title {
  color: #989898;
  font-family: Nutmeg-Bold, serif;
  font-size: 1rem;
}

.post-content {
  font-family: Nutmeg-Book, serif;
  font-weight: normal;
  font-size: 14px;
  color: #495057 !important;
  line-height: 1.6;
}

.post-title {
  color: #585c60;
  font-family: Nutmeg-Bold, serif;
  font-size: 15px;
}

.post-title:hover {
  text-decoration: none;
  color: #585c60;
  font-family: Nutmeg-Bold;
}

.btn-more-posts {
  color: #e93251 !important;
  background-color: #ffffff !important;
  span {
    font-size: 14px !important;
  }
}

.btn-more-posts:disabled,
.btn-more-posts[disabled] {
  color: #989898 !important;
  background-color: #cacaca !important;
}

.button-section {
  display: flex;
  flex-wrap: wrap;
}
.writebox-section-check {
  padding: 0 5px 0 0 !important;
  .MuiSvgIcon-root {
    font-size: 1.2rem !important;
    color: #e93251 !important;
  }
}

.writebox-section-check-right {
  border-left: solid 2px #bcbcbc !important;
}

.writebox-media-group span {
  font-size: 11px !important;
}

.blog-date-input {
  background: #F1F3F4;
  // width: fit-content;
  display: flex;
  align-items: center;
  font-family: Nutmeg-Book;
  font-size: 1rem;
  justify-content: space-between;
  &.report-modal{
    label {
      color: $mi_muro-blue-modals;
      display: flex;
      align-items: center;
      font-size: 1.5rem;
    }
    input{
      border-radius: 20px;
    }
  }
  label {
    color: #E93251;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
  }
  input {
    -webkit-appearance: media-slider !important;
    background: #F1F3F4;
    color: #606469;
  }
  input::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
}

.blog-modal-card-title {
  color: #606469;
  padding-left: 8px;
  font-size: 16px;
  height: auto;
  font-family: Nutmeg-Bold;
  line-height: 1;
}

.blog-modal-card-content {
  color: #606469;
  font-size: 14px;
  font-family: Nutmeg-Book;
  margin: 0;
  padding-left: 16px;
}

.blog-modal-card-badge {
  background: #FDC82E;
  color: #FDC82E;
  width: 6px;
  height: 100%;
  border-radius: 5px;
}

@media all and (max-width: 767px) {
  .writebox-section-check-right {
    border-left: none !important;
  }
}

@media all and (max-width: 1500px) and (min-width: 100px) {
  .button-section {
    margin-left: 0 !important;
  }
}
