@font-face {
  font-family: 'icomoon';
  src:  url('../assets/fonts/icomoon/icomoon.eot?558iap');
  src:  url('../assets/fonts/icomoon/icomoon.eot?558iap#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon/icomoon.ttf?558iap') format('truetype'),
    url('../assets/fonts/icomoon/icomoon.woff?558iap') format('woff'),
    url('../assets/fonts/icomoon/icomoon.svg?558iap#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'icomoon-notifications';
  src:  url('../assets/fonts/icomoon-notifications/icomoon-notifications.eot?702nko');
  src:  url('../assets/fonts/icomoon-notifications/icomoon-notifications.eot?702nko#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon-notifications/icomoon-notifications.ttf?702nko') format('truetype'),
    url('../assets/fonts/icomoon-notifications/icomoon-notifications.woff?702nko') format('woff'),
    url('../assets/fonts/icomoon-notifications/icomoon-notifications.svg?702nko#icomoon-notifications') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconNotif-"], [class*=" iconNotif-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-notifications' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'icomoon2';
  src:  url('../assets/fonts/icomoon2/icomoon2.eot?umid9b');
  src:  url('../assets/fonts/icomoon2/icomoon2.eot?umid9b#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon2/icomoon2.ttf?umid9b') format('truetype'),
    url('../assets/fonts/icomoon2/icomoon2.woff?umid9b') format('woff'),
    url('../assets/fonts/icomoon2/icomoon2.svg?umid9b#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-2-"], [class*=" icon-2-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon2' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'icomoon3';
  src:  url('../assets/fonts/icomoon3/icomoon3.eot?46gih6');
  src:  url('../assets/fonts/icomoon3/icomoon3.eot?46gih6#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon3/icomoon3.ttf?46gih6') format('truetype'),
    url('../assets/fonts/icomoon3/icomoon3.woff?46gih6') format('woff'),
    url('../assets/fonts/icomoon3/icomoon3.svg?46gih6#icomoon3') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-3-"], [class*=" icon-3-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon3' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'icomoon4';
  src:  url('../assets/fonts/icomoon4/icomoon4.eot?sup74m');
  src:  url('../assets/fonts/icomoon4/icomoon4.eot?sup74m#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon4/icomoon4.ttf?sup74m') format('truetype'),
    url('../assets/fonts/icomoon4/icomoon4.woff?sup74m') format('woff'),
    url('../assets/fonts/icomoon4/icomoon4.svg?sup74m#icomoon4') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-4-"], [class*=" icon-4-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon4' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'icomoon5';
  src:  url('../assets/fonts/icomoon5/icomoon5.eot?rxk55q');
  src:  url('../assets/fonts/icomoon5/icomoon5.eot?rxk55q#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon5/icomoon5.ttf?rxk55q') format('truetype'),
    url('../assets/fonts/icomoon5/icomoon5.woff?rxk55q') format('woff'),
    url('../assets/fonts/icomoon5/icomoon5.svg?rxk55q#icomoon5') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-5-"], [class*=" icon-5-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon5' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'icomoon6';
  // src:  url('../assets/fonts/icomoon6/icomoon6.eot?rxk55q');
  // src:  url('../assets/fonts/icomoon6/icomoon6.eot?rxk55q#iefix') format('embedded-opentype'),
  //   url('../assets/fonts/icomoon6/icomoon6.ttf?rxk55q') format('truetype'),
  //   url('../assets/fonts/icomoon6/icomoon6.woff?rxk55q') format('woff'),
  //   url('../assets/fonts/icomoon6/icomoon6.svg?rxk55q#icomoon5') format('svg');
    // src:  url('../assets/fonts/icomoon6/icomoon6.eot?30xczj');
    // src:  url('../assets/fonts/icomoon6/icomoon6.eot?30xczj#iefix') format('embedded-opentype'),
    //   url('../assets/fonts/icomoon6/icomoon6.ttf?30xczj') format('truetype'),
    //   url('../assets/fonts/icomoon6/icomoon6.woff?30xczj') format('woff'),
    //   url('../assets/fonts/icomoon6/icomoon6.svg?30xczj#icomoon') format('svg');
      src:  url('../assets/fonts/icomoon6/icomoon6.eot?j51qy4');
  src:  url('../assets/fonts/icomoon6/icomoon6.eot?j51qy4#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon6/icomoon6.ttf?j51qy4') format('truetype'),
    url('../assets/fonts/icomoon6/icomoon6.woff?j51qy4') format('woff'),
    url('../assets/fonts/icomoon6/icomoon6.svg?j51qy4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-6-"], [class*=" icon-6-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon6' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'icomoon7';
  src:  url('../assets/fonts/icomoon7/icomoon7.eot?j7ngz8');
  src:  url('../assets/fonts/icomoon7/icomoon7.eot?j7ngz8#iefix') format('embedded-opentype'),
    url('../assets/fonts/icomoon7/icomoon7.ttf?j7ngz8') format('truetype'),
    url('../assets/fonts/icomoon7/icomoon7.woff?j7ngz8') format('woff'),
    url('../assets/fonts/icomoon7/icomoon7.svg?j7ngz8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-7-"], [class*=" icon-7-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon7' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// @font-face {
//   font-family: 'icomoon';
//   src:  url('fonts/icomoon.eot?j7ngz8');
//   src:  url('fonts/icomoon.eot?j7ngz8#iefix') format('embedded-opentype'),
//     url('fonts/icomoon.ttf?j7ngz8') format('truetype'),
//     url('fonts/icomoon.woff?j7ngz8') format('woff'),
//     url('fonts/icomoon.svg?j7ngz8#icomoon') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: block;
// }

// [class^="icon-"], [class*=" icon-"] {
//   /* use !important to prevent issues with browser extensions that change fonts */
//   font-family: 'icomoon' !important;
//   speak: never;
//   font-style: normal;
//   font-weight: normal;
//   font-variant: normal;
//   text-transform: none;
//   line-height: 1;

//   /* Better Font Rendering =========== */
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

.icon-7-SEJ_Inicio:before {
  content: "\e94c";
  font-size: xx-large;
}

.icon-SECADMON_Blog_de_colaboracion:before {
  content: "\e900";
}

.icon-SECADMON_blog_Privado:before {
  content: "\e901";
}

.icon-SECADMON_Grupos_de_trabajo:before {
  content: "\e902";
}

.icon-SECADMON_Perfil_cumpleaos:before {
  content: "\e903";
}

.icon-SECADMON_Perfil_Grupos:before {
  content: "\e904";
}

.icon-SECADMON_Perfil_Habilidades:before {
  content: "\e905";
}

.icon-SECADMON_Perfil_Organizacion:before {
  content: "\e906";
}

.icon-SECADMON_Blog-P_Permitir-comentarios:before {
  content: "\e907";
}

.icon-SECADMON_Blog-P_Anclar-publicacin:before {
  content: "\e908";
}

.icon-SECADMON_Blog-P_Bloquear-comentarios:before {
  content: "\e909";
}

.icon-SECADMON_Blog-P_Desanclar-publicacin:before {
  content: "\e90a";
}

.icon-SECADMON_Blog-P_Eliminar-publicacin:before {
  content: "\e90b";
}

.icon-SECADMON_Blog-P_Editar-publicacin:before {
  content: "\e90c";
}

.icon-SECADMON_Descargar:before {
  content: "\e90d";
}
.icon-SECADMON_Mis-trmites_En-proceso:before {
  content: "\e90e";
}

.icon-SEJ_Tramites_aclaraciones:before {
  content: "\e90f";
}
.icon-SEJ_Tramites_educacion-y-cultura:before {
  content: "\e910";
}
.icon-SEJ_Tramites_informatica .path1:before {
  content: "\e911";
}
.icon-SEJ_Tramites_informatica .path2:before {
  content: "\e912";
  margin-left: -1.12890625em;
}
.icon-SEJ_Tramites_informatica .path3:before {
  content: "\e913";
  margin-left: -1.12890625em;
}
.icon-SEJ_Tramites_informatica .path4:before {
  content: "\e914";
  margin-left: -1.12890625em;
}
.icon-SEJ_Tramites_personal:before {
  content: "\e915";
}
.icon-SEJ_Tramites_ejercicio-presupuestal:before {
  content: "\e916";
}

.icon-SEJ_Mi_expediente:before {
  content: "\e917";
}

.icon-SEJ_Reportes:before {
  content: "\e918";
}

.icon-SEJ_Tramites:before {
  content: "\e919";
}

.icon-SEJ_Inicio:before {
  content: "\e94c";
  font-size: xx-large;
}

.icon-SEJ_Tramites_servicios-legales:before {
  content: "\e91b";
}

.icon-SEJ_Tramites_nomina:before {
  content: "\e91c";
}

.icon-SEJ_Tramites_recursos-materiales:before {
  content: "\e91d";
}

.icon-SEJ_Tramites_servicios-generales:before {
  content: "\e91e";
}

.icon-SEJ_Menu-tramites_Mensaje:before {
  content: "\e91f";
}

.icon-4-SEJ_Tramites_educacion-media-superior:before {
  content: "\e93a";
}

// .icon-Mi-muro_Reagendar_cita:before {
//   content: "\e900";
// }

// ICONOS NOTIFICACIONES

.iconNotif-Mi-muro_Notificaciones_tarea-realizada:before {
  content: "\e920";
}

.iconNotif-Mi-muro_Notificaciones_no-inhabilitada:before {
  content: "\e921";
}

.iconNotif-Mi-muro_Notificaciones_por-inhabilitar:before {
  content: "\e922";
}

.iconNotif-Mi-muro_Notificaciones_solicitud-actualizada:before {
  content: "\e923";
}

.iconNotif-Mi-muro_Notificaciones_actualizacion-pregunta:before {
  content: "\e924";
}

.iconNotif-Mi-muro_Notificaciones_aprobacion:before {
  content: "\e925";
}

.iconNotif-Mi-muro_Notificaciones_aut-normativa-tecnica:before {
  content: "\e926";
}

.iconNotif-Mi-muro_Notificaciones_avisos:before {
  content: "\e927";
}

.iconNotif-Mi-muro_Notificaciones_envio-solicitud:before {
  content: "\e928";
}

.iconNotif-Mi-muro_Notificaciones_inhabilitada:before {
  content: "\e929";
}

.iconNotif-Mi-muro_Notificaciones_seguimiento-de-tramite:before {
  content: "\e92a";
}

.iconNotif-Mi-muro_Notificaciones_rechazo:before {
  content: "\e92b";
}

.iconNotif-Mi-muro_Notificaciones_respuesta-comentario:before {
  content: "\e92c";
}

.iconNotif-Mi-muro_Notificaciones_expediente-digital:before {
  content: "\e92d";
}

.iconNotif-Mi-muro_Notificaciones_encuestas:before {
  content: "\e938";
}

.iconNotif-Mi-muro_Notificaciones_expediente:before {
  content: "\e939";
}

//Icono Reagendar cita

.icon-2-Mi-muro_Reagendar_cita:before {
  content: "\e92e";
}

.icon-3-Mi-muro_Formularios_CCT:before {
  content: "\e92f";
}

//ICONOS ENCUESTAS

.icon-2-Mi-muro_resultados_individuales:before {
  content: "\e92f";
}

.icon-2-Mi-muro_resultados_globales:before {
  content: "\e935";
}

.icon-2-Mi-muro_Encuestas:before {
  content: "\e930";
}

.icon-2-Mi-muro_encuestas_convocatorias-1:before {
  content: "\e931";
}

.icon-2-Mi-muro_Encuestas_resultados3:before {
  content: "\e932";
}

.icon-2-Mi-muro_Encuestas_resultados4:before {
  content: "\e933";
}

.icon-2-Mi-muro_Encuestas_ver-detalles:before {
  content: "\e934";
}

.icon-2-Mi-muro_Encuestas_resultados1:before {
  content: "\e936";
}

.icon-2-Mi-muro_Encuestas_resultados2:before {
  content: "\e937";
}

.icon-5-Mi-muro_edit-tutorial:before {
  content: "\e93b";
}
.icon-5-Mi-muro_Admin-tutoriales:before {
  content: "\e93c";
}
.icon-5-Mi-muro_subir-archivo:before {
  content: "\e93d";
}

// .icon-6-Mi-muro_admin:before {
//   content: "\e94b";
// }
.icon-6-Administracion_Mi-muro:before {
  content: "\e95c";
}
.icon-6-Usuraios_Mi-muro:before {
  content: "\e95c";
}
.icon-6-Administracion_Mi-muro:before {
  content: "\e95c";
}
.icon-6-Administracion_Mi-muro:before {
  content: "\e95c";
}
.icon-6-Usuarios_Mi-muro:before {
  content: "\e93e";
}
.icon-6-Tutoriales_Mi-muro:before {
  content: "\e93f";
}
.icon-6-Dias_Mi-muro:before {
  content: "\e94a";
}
.icon-6-Categorias_Mi-muro:before {
  content: "\e94b";
}

// Last icon code = \e93d