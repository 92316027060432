$defaultColor: #0096bc;

.bg-procedures {
  background-color: #ffffff !important;
}

.procedures-tabs {
  button {
    color: #989898 !important;
    font-family: Nutmeg-Bold;
  }
  border-bottom: 1px solid #cccccc;
  .indicator {
    background-color: $defaultColor !important;
  }
}

.my-procedures-link {
  .my-procedures-link-button {
    color: #1898bf;

    &:hover {
      cursor: pointer;
    }
  }
}

.my-procedures-download-link {
  color: #606469;
  text-decoration: none;

  &:hover {
    color: #1898bf;
    text-decoration: none;
  }
}

h3.section-title {
  color: #989898;
  font-size: 18px;
  margin-bottom: 6px;
}

div.section-title-line {
  width: 150px;
  height: 2px;
  border-left: 25px solid #7fb91e;
  background-color: #e6e6e6;
  margin-bottom: 10px;
}

.work-area-select {
  //background-color: #f7edce;
  border-radius: 20px;

  label {
    color: #000 !important;
    font-weight: bold;
  }

  div {
    input,
    button {
      color: #000 !important;
    }

    fieldset, fieldset:hover {
      // border-color: transparent !important;
      border: 1px #FECA1F solid !important;
      border-radius: 20px;
    }
  }

  .Mui-focused {
    color: #000 !important;
  }
}

.btn-procedures:enabled {
  background-color: #7fb91e !important;
}

.btn-procedures:disabled {
  background-color: #809b52 !important;
}

.btn-procedures-follow-up:enabled {
  background-color: #7fb91e !important;
}

.btn-procedures-follow-up-finished:enabled {
  background-color: #8bd0ce !important;
  cursor: unset;
  padding: 10px;
}

.user-info-section {
  padding-right: 0;
  padding-left: 30px;
}

.user-info-section-border {
  border-right: solid 1px #bababa;
}

.user-info-section-2 {
  padding-left: 5rem;
}

.text-procedures {
  color: #0096bc !important;
}

@media all and (max-width: 374px) and (min-width: 100px) {
  .user-info-section {
    border-right: none;
  }
  .btn-procedures-banner-top {
    background-color: #ffffff !important;
    color: #1898bf !important;
    margin-bottom: 10px !important;
    margin-left: unset !important;
    &.back-btn{
      border: 1px solid #FFFFFF !important;

    }
  }
}

@media all and (max-width: 415px) and (min-width: 375px) {
  .user-info-section {
    border-right: none;
  }
  .btn-procedures-banner-top {
    background-color: #ffffff !important;
    color: #1898bf !important;
    margin-bottom: unset !important;
    margin-left: unset !important;
    &.back-btn{
      border: 1px solid #FFFFFF !important;

    }
  }
}

@media all and (max-width: 1500px) and (min-width: 200px) {
  .user-info-section-2 {
    padding-left: 30px;
  }
}
.btn-procedures-banner-top {
  background-color: #D5264F !important;
  color: white !important;
  border-radius: 20px !important;
  padding: 2px !important;

  &.back-btn{
    border: 1px solid #FFFFFF !important;

  }
}

.btn-procedures-banner {
  background-color: #D5264F !important;
  color: white !important;
  border-radius: 20px !important;
  padding: 2px !important;
}

.btn-not-margin {
  margin: 0px !important;
}

.btn-margin-bottom {
  margin-bottom: 5px !important;
}

.procedures-stats-card-header {
  color: #606469 !important;
}

.procedures-stats-card-header-v2 {
  background-color: #bababa !important;
}

.procedures-card-body {
  // border-left: solid 1px #cccccc !important;
  // border-right: solid 1px #cccccc !important;
  // border-bottom: solid 1px #cccccc !important;
  // border-radius: 0 0 5px 5px;
}

// .procedures-card-body > .card-body {
//   .procedure-card-section:nth-child(2n + 1) {
//     background-color: #f2f8e8;
//   }
// }

.procedures-card-item {
  border-left: 5px solid #7fb91e;
  background-color: #f8f8f8;
  color: #606469;
  margin-bottom: 10px !important;
  padding: 5px;
  font-size: 12px;
  font-family: Nutmeg-Regular;
}

.procedure-item-even {
  background-color: #eff8fb;
}

.procedure-item-description-title {
  color: #606469;
  font-family: Nutmeg-Bold;
}

.procedure-item-description {
  color: #606469;
  font-family: Nutmeg-Book;
}

.procedure-item-title {
  color: #1898bf;
  font-family: Nutmeg-Bold;
}

.procedure-detail-banner {
  padding: unset !important;
  margin-bottom: unset !important;
  height: fit-content !important;
  background-size: cover !important;
  display: flex !important;
  flex-direction: column-reverse !important;
  background-color: transparent !important;
  // background-image: url("../../assets/images/SECADMON_Banner_Trámites.png") !important;
}

@media all and (max-width: 415px) and (min-width: 100px) {
  .procedure-detail-banner {
    padding: unset !important;
    margin-bottom: unset !important;
    height: fit-content !important;
    background-size: cover !important;
    display: flex !important;
    flex-direction: column-reverse !important;
    background-color: transparent !important;
    // background-image: url("../../assets/images/SECADMON_Banner_Trámites.png") !important;
  }
}

.procedure-banner-title {
  color: #606469 !important;
  font-size: 24px;
}

.procedure-subtitle {
  color: #606469;
  font-family: Nutmeg-Bold;
  font-size: 1rem;
}

.procedure-action-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.procedure-card-requirement {
  background-color: transparent;
  div:nth-child(1){
    color: #404040;
  }
  div:nth-child(2){
    color: #67737A;
  }
}

.procedure-card-requirement-name {
  color: #67737A;
  font-size: 14px;
  font-family: Nutmeg-Book;
  margin: 0;
  padding-left: 5px;
}

.procedure-card-top {
  background-color: #f1f3f4;
  border-left: #7fb91e solid 8px;
  padding: 9px 8px;
  margin-bottom: 10px;
}

.border-left-green {
  border-left: #7fb91e solid 8px;
}

.show-more {
  color: #7fb91e;
  font-family: Nutmeg-Regular;
  font-size: 14px;
}

.procedure-card-title {
  color: #606469;
  border-left: #FDC82E solid 8px;
  padding-left: 8px;
  font-size: 16px;
  height: auto;
  font-family: Nutmeg-Bold;
  line-height: 1;
  margin-bottom: 0.4rem;
}
.procedure-card-subtitle {
  color: #606469;
  font-size: 14px;
  font-family: Nutmeg-Regular;
}
.procedure-card-content {
  color: #606469;
  font-size: 14px;
  font-family: Nutmeg-Book;
  margin: 0;
  padding-left: 16px;

  &.link{
    a{
      color: #3FA7E1 !important;
      font-family: Prompt-Regular;
      font-weight: bold;
    }

  }
}

.likes-list-info {
  color: #606469;
  border-left: #FDC82E solid 5px;
  padding-left: 8px;
  font-size: 14px;
  height: auto;
  // font-family: Nutmeg-Bold;
  font-family: Nutmeg-Book;
  line-height: 1;
  margin-bottom: 0.4rem;
}

.steps-container {
  a {
    color: #00bcd4;
  }
}

.procedure-card-section {
  margin-top: 10px;
  //border-bottom: solid 1px #cccccc;
  padding-bottom: 0.8rem;
}

.procedure-table-item {
  background-color: #ffffff;
}
.procedure-table-item-even {
  background-color: transparent;
}

.procedure-category-title {
  color: #989898 !important;
}

.delete-procedure-modal-title {
  color: #606469 !important;
  font-family: Nutmeg-Regular !important;
  size: 14px !important;
}

.delete-procedure-modal-content {
  color: #606469 !important;
  font-family: Nutmeg-Book !important;
  size: 14px !important;
}

.procedure-row {
  font-size: 0.8rem;
  font-family: Nutmeg-Book;

  &:nth-child(2n + 1) {
    background-color: #fff;
  }

  &:nth-child(2n) {
    background-color: #f8f8f8;
  }
}

.procedure-row-even {
  background-color: #eff8fb;
}

.procedure-row td button {
  background-color: #1898bf;
}

.procedure-row td button:hover {
  background-color: #0d4f63;
}

.my-procedures-table-header {
  font-family: Nutmeg-Bold;
  color: #606469;
  font-size: 0.8rem !important;
  border-bottom: 1px solid #000;
}

.my-procedures-table-icon {
  font-size: 1rem;
}

.my-procedures-table-icon.zmdi-check {
  font-weight: bold;
}

.my-procedures-table-responsible {
  font-family: Nutmeg-Regular;
}

.procedure-row td {
  vertical-align: middle !important;
}

.update-procedure-header {
  color: #606469;
}

.update-procedure-cancel-button {
  color: #989898 !important;
}

.procedure-filter-select {
  border-radius: 20px;
  border: solid 1px #7C858C;
  height: calc(1.5em + 0.75rem + 2px);
  font-family: Prompt-Regular;
  font-size: 0.875rem;
  width: 100%;
  background-position-x: calc(100% - 0.5rem);
  @media (min-width: 768px) {
    min-width: 60%;
  }
}