.card-img {
  min-height: 232px;

  img {
    width: 100%;
    height: 232px;
    border-radius: 20px 20px 0 0;
  }
}

.video-play-button {
  height: 232px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  position: absolute;
  top: 0;

  .play-button-container {
    border-radius: 50%;
    background: #E70E4C;
    opacity: 0.8;
    aspect-ratio: 1/1;
    min-height: 35%;
  }
}

.tutorial_external_video {
  min-width: 640px;
  min-height: 480px;
  @media (max-width: 990px) {
    width: 100%;
    min-width: unset;
    min-height: unset;
  }
}