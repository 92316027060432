.chip {
  border-radius: 10px;
  display: flex;
}

.chip-highlight {
  width: 10px;
  border-radius: 10px 0 0 10px;
}

.chip.success {
  background-color: #b9dfab;
}

.chip.success .chip-highlight {
  background-color: #51AF2C;
}

.chip.danger {
  background-color: #f4dfac;
}

.chip.danger .chip-highlight {
  background-color: #F9C647;
}

.chip.error {
  background-color: #f59fb7;
}

.chip.error .chip-highlight {
  background-color: #E70E4C;
}

.chip.disabled {
  background-color: #cecece;
}

.chip.disabled .chip-highlight {
  background-color: #a0a0a0;
}

.chip.info {
  background-color: #B2DCF3;
}

.chip.info .chip-highlight {
  background-color: #3FA7E1;
}