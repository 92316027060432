.work-group {
  color: #18A19D !important;
}

.btn-work-group-dismiss {
  border: 1px solid #7FB91E !important;
  color: #7FB91E !important;
}

.btn-work-group-save {
  color: #ffffff !important;
  background-color: #7FB91E !important;
}

.btn-work-group-save:disabled,
.btn-work-group-save[disabled]{
  background-color: #cacaca !important;
}
