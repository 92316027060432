// -----------------------------------
// Variables --- Start
@import "colors";
// Variables --- End
// -----------------------------------

.postItem__actionsMenu__option {
  span {
    color: #606469;
  }
}
div{
  overflow-wrap: break-word;
}
.postItem__actionsMenu__option:hover {
  background-color: #fdeaed !important;

  i,
  span {
    color: #e93251;
  }

  &.PrivateBlog {
    background-color: #f0d3e3 !important;

    i,
    span {
      color: #b32572;
    }
  }
}

.notifItem__actionsMenu__option {
  span {
    color: #606469;
  }
}

.notifItem__actionsMenu__option:hover {
  background-color: #f1edf8 !important;

  i,
  span {
    color: #7e57c5;
  }

  &.PrivateBlog {
    background-color: #f1edf8 !important;

    i,
    span {
      color: #7e57c5;
    }
  }
}

.br-word {
  word-break: break-word;
}

.sidenav-logo {
  display: flex;
  align-items: center;
  padding-left: 20px;

  .titleLogo {
    color: black;
    padding-left: 8px;
    letter-spacing: 6px;
    font-size: 16px;

    .iLetter {
      color: black;
      position: relative;

      &:before {
        content: "•";
        position: absolute;
        color: #feca1f;
        top: -7px;
        left: -1px;
        font-size: 16px;
      }
    }
  }
}

.nav-text.customColor {
  color: #606469;
}

li.open > a {
  background-color: #fff !important;
  color: #a1a1a1 !important;
}

.collapseButton {
  padding: 0 !important;
  box-shadow: none !important;
}

.btn-box-shadow {
  box-shadow: 0 2px 5px 0 #cc6342 !important;
}

.textShadow {
  text-shadow: 2px 2px 5px #ef734c;
}

/**  **/
.icon-SECADMON_Grupos_de_trabajo {
  vertical-align: top;
}

.MuiButton-textPrimary:hover {
  background-color: #e9ecef3d;
}

.Line {
  border-bottom: 1px solid #e1e1e1 !important;
}

.MuiButton-label {
  font-family: "Nutmeg-Regular", serif;
  font-size: 12px;
}

.MuiLinearProgress-bar {
  background-color: #1898bf !important;
}

.MuiLinearProgress-root {
  background-color: inherit !important;
  border-radius: 5px;
}

.card {
  border: solid 1px #cacaca;
  box-shadow: none;
}

.card-header {
  background-color: rgba(0, 0, 0, 0);
}

.app-login-header {
  color: #000000;
}

.zmdi-more-vert {
  color: #e93251;
}

@mixin login-button {
  box-shadow: none !important;
  border: solid 1px #e93251 !important;
  color: #e93251 !important;
  font-family: "Nutmeg-Regular", serif !important;
  font-size: 13px !important;
  margin-bottom: 2.5rem !important;
  border-radius: 9px !important;
  div {
    border-radius: 9px !important;
  }
}

.login-redirect-teacher {
  @include login-button;
  background-color: #ffffff !important;
  p {
    margin: 0.5rem !important;
    color: #e93251 !important;
    font-size: 13px !important;
  }
}

.login-redirect-back button {
  @include login-button;
  background-color: #ffffff !important;
  p {
    font-size: 13px !important;
  }
}

.login-redirect-admins {
  @include login-button;
  background-color: #ffffff !important;
  margin-bottom: 4rem !important;
  p {
    margin: 0.5rem !important;
    color: #e93251 !important;
    font-size: 13px !important;
  }
}

.login-button-teacher button {
  @include login-button;
}

.login-button-admins button {
  @include login-button;
  margin-bottom: 4rem !important;
}

.zmdi-notifications-none {
  color: #18a19d;
  font-weight: bold;
}

.user-detail .user-name {
  cursor: default;
  max-width: 300px;
  text-overflow: ellipsis;

  @media (max-width: 750px) {
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
  }

  @media (max-width: 630px) {
    max-width: 150px;
  }

  @media (max-width: 600px) {
    max-width: 120px;
  }

  @media (max-width: 400px) {
    max-width: 85px;
    margin-left: 0 !important;
    display: none;
  }
}

.header-notification-profile-data {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 945px) {
  .header-notification-profile-data .user-profile .header-user-info {
    @media only screen and (max-width: 650px) {
      flex-direction: column-reverse;
      align-items: flex-end;
    }

    .header-rol-name .user-name .roles-default-3 {
      display: none;
    }
  }
}

.header-user-info {
  display: flex;
  flex-direction: row;
  align-items: center;

  .header-rol-name {
    display: flex;
    flex-direction: row !important;

    .line-hidden {
      display: none;
    }
  }

  @media only screen and (min-width: 946px) {
    & {
      .header-rol-name .line-hidden {
        display: initial;
      }
    }
  }
}

.private-blog {
  color: #b32572;
}

.SpaceBlogs {
  @media only screen and (max-width: 767px) {
    flex-direction: column-reverse !important;

    .LinkSectionSpace {
      margin-bottom: 30px;
    }
  }
}

.ProfileCustomIcons {
  color: #e93251;
  font-size: 25px;
}

.DatePickerCustom.MuiButton-outlinedPrimary {
  color: #b32572;
  border: 1px solid rgba(179, 37, 114, 0.5);

  &:hover {
    background-color: #f0d3e3;
    border: 1px solid rgba(179, 37, 114, 0.5);
  }

  span,
  i {
    color: #b32572;
  }
}

.CheckboxPrivateBlogCustom {
  color: #b32572 !important;
}

.CheckboxWorkGroupsCustom {
  color: #1171ce !important;
}

.CheckboxCustomNotification {
  padding: 0 5px 0 0 !important;
  .MuiSvgIcon-root {
    font-size: 1.2rem !important;
    color: #7e57c5 !important;
  }
}

.ButtonPrivateBlogCustom.MuiButton-containedPrimary,
.ButtonPrivateBlogCustom.MuiButton-containedPrimary:hover {
  background-color: #b32572;
}

.EmojiButton {
  color: #e93251 !important;

  i {
    font-size: 18px;
  }

  &.EmojiButtonPrivateBlog {
    color: #b32572 !important;
  }
}

.text-privateBlog {
  color: #b32572 !important;
}

.PrivateBlogDatePicker:not(.Mui-disabled),
.PrivateBlogDatePicker:not(.Mui-disabled):hover {
  background-color: #b32572;
}

.blog-range-picker {
  .MuiPickersDateRangeDay-rangeIntervalDayHighlight {
    background-color: #f0d3e3 !important;
  }
}

.private-blog-range-picker {
  .MuiPickersDay-root.Mui-selected {
    background-color: #b32572 !important;
  }

  .MuiPickersDateRangeDay-rangeIntervalDayHighlight {
    background-color: #f0d3e3 !important;
  }
}

.private-blog-button:enabled {
  background-color: #b32572 !important;
}

.procedures-footer {
  border-bottom: none !important;
}

.emoji-picker-react {
  @media only screen and (max-width: 445px) {
    width: unset !important;
  }
}

.scrollbar-control {
  @media (max-width: 976px) {
    margin-right: 0 !important;
  }
}

.work-group-select > div > fieldset {
  border-color: $mi_muro-blue-modals !important;
}

.work-group-select > .Mui-focused {
  color: $mi_muro-blue-modals !important;
}

.contact-card-content {
  padding-top: 20px !important;
}

.answersTitle {
  font-family: Nutmeg-Bold, serif;
  // color: #606469;
  color: white;
}

.answersHeader {
  font-family: Nutmeg-Regular, serif !important;
  color: #ffffff !important;
}

ul.sub-menu > li > a::after {
  content: none !important;
}

.TextViewMore {
  display: inline-block;
  color: #e93251;
}

.TextViewMore:hover {
  cursor: pointer;
  text-decoration: underline;
}

.comment-stream__line {
  display: flex;
  width: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.margin-left {
  border-left: solid 2px #dee2e6;
  padding-left: 0px;
  margin-left: 0px;

  &.container {
    .margin-left__content {
      margin-left: -11px;
    }
  }
}
.jr-wall-comment-box.stream {
  border-left: solid 2px #dee2e6;
}
.jr-wall-comment-box.reply {
  padding-left: 42px;
  margin-left: -37px;
}
.jr-wall-comment-box.reply.second-lvl {
  margin-left: -27px;
}
.jr-wall-comment-box.reply.deep {
  padding-left: 42px;
  margin-left: -42px;
  padding: 0;
}
.comment-stream__line.reply {
  display: flex;
  flex-direction: row-reverse;
}
.comment-stream__line.reply > .stream__line-left {
  height: 1px;
  width: 44px;
  margin-left: -44px;
  border-bottom: solid 2px #dee2e6;
}
.stream-line__icon {
  margin-right: 20px;
}
.comment-stream__line > .stream__line-left {
  height: 1px;
  width: 44px;
  margin-left: -44px;
  border-bottom: solid 2px #dee2e6;
}

.comment-stream__line.reply > .stream__line-left.deep {
  height: 0;
  width: 0;
  margin-left: -17px;
  border-bottom: solid 2px #dee2e6;
}

.dropzone-margin-custom {
  max-width: 100%;
  height: auto;
}

.dropzone-content {
  align-self: center;
  width: 25%;

  @media (max-width: 867px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
}

.btn-add-files {
  cursor: pointer !important;
  input {
    display: none !important;
  }
}
.procedure-row td .btn-viewMore {
  background-color: #a61f4f;
  width: 104px;

  &:hover {
    background-color: #a61f4f;
  }
}
.procedure-row td .detail-col {
  display: flex;
  flex-direction: column;
  color: #a61f4f;
  justify-content: center;
  align-items: center;
  gap: 10px;

  span {
    &:hover {
      cursor: pointer;
    }
  }

  img {
    width: 25px;
    height: 32px;

    &:hover {
      cursor: pointer;
    }
  }
}

.sendRequestLink {
  color: #a61f4f;
  &:hover {
    color: #bb3464;
  }
}

.data-update-not-aproved-reason {
  font-family: "Nutmeg-Book";
}
.FormSection {
  margin: 1.5rem 0 0 3rem;
  @media (max-width: 480px) {
    margin: 1.5rem 0 0 -3.5rem;
  }
}
.form-maker__add-question-btns {
  @media (max-width: 480px) {
    text-align: center;
    :first-child {
      width: 100%;
      i {
        width: 15px;
      }
    }
    :last-child {
      margin-top: 10px;
      width: 100%;
    }
  }
}
.form-section__title {
  background-color: #558816;
  color: white;
  height: 40px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  p {
    font-size: 18px;
    margin-top: 20px;
  }
}

.question-form-card-border {
  background-color: $mi_muro-magenta;
  padding-left: 10px;
  border-radius: 15px;
}

.question-form-card {
  //padding: 5px 30px 20px !important;
  // padding: 5px 30px 20px 0px !important;
  border: 1px solid #d3d3d3;
  // border-radius: 5px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 100% 2fr;
  background-color: white;

  &.editing-or-dragg {
    border-left: 8px solid $mi_muro-magenta;
  }

  &.complete-border {
    border-radius: 15px;
  }

  .question {
    // grid-column: 1;
    padding: 5px 30px 20px 20px !important;
  }
  .question__drag-zone {
    display: flex;
    justify-items: center;
    align-items: center;
    width: 15px;
    margin-left: 15px;

    img {
      visibility: hidden;

      &.visible {
        visibility: visible;
      }
    }
  }
}

.question_header {
  display: grid;
  justify-content: flex-end;
  grid-template-columns: 99% 2fr;

  .questions__validations {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    //grid-template-columns: 20% repeat(3, 1fr) ;

    @media (max-width: 800px) {
      flex-direction: column;
      justify-content: flex-start;
      display: grid;
      align-items: flex-start;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 25px);

      .question_validations--items hr {
        visibility: hidden;
      }
    }

    @media (max-width: 555px) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 25px);
    }

    .question_validations--items {
      display: flex;

      span {
        font-size: 11px;
        //color:#606469;
      }
      hr {
        height: 25px;
        align-self: center;
        width: 2px;
      }
    }
  }
  .question__header-menu {
    display: flex;
    align-items: center;
  }
}

.QuestionColor {
  color: #7fb91e;
}

.question__option {
  display: flex;
  .drag-zone-left.radio {
    margin-left: -15px;
    margin-top: 25px;
  }

  .drag-zone-left {
    margin-left: -15px;
    margin-top: 20px;
    margin-right: -10px;

    img {
      width: 15px;
      height: 15px;

      visibility: hidden;
      &.visible {
        visibility: visible;
      }
    }
  }
}
.option__icon-close:hover {
  cursor: pointer;
}

.question__fileTypes {
  font-family: "Nutmeg-Book" !important;

  display: flex;
  flex-direction: column;
  color: #606469;
  font-family: "";
}
.question_linealScale {
  color: #606469;
  font-style: normal;

  .question__linealScale--iconSelect {
    pointer-events: none;
    cursor: pointer;
  }
}
.question__valuesMatrix {
  .question__valuesMatrix--txt {
    width: 80%;
  }
}

.template--list__card {
  max-width: 352px;

  background-color: #f8f8f8;
  border-radius: 8px;
  display: grid;
  // grid-template-columns: 90% 2fr ;
  grid-template-rows: 28px auto auto;
  padding: 10px 10px 20px 15px;

  @media (max-width: 480px) {
    max-width: 320px;
  }

  &:hover {
    background-color: #f2f8e8;
  }
  .card-opc {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 5px;
  }

  .card-title {
    width: 100%;
    font-size: 18px;
    display: grid;
    margin: 0 auto;
    padding: 0 15px 0 15px;
    grid-template-columns: 9% 91%;
    span {
      text-transform: none;
      &:hover {
        cursor: pointer;
      }
    }
    img {
      margin-right: 5px;
      width: 21px;
      height: 24px;
    }
  }
  img {
    margin-right: 5px;
    width: 21px;
    height: 24px;
  }
}

.card-description {
  min-height: 50px;
  overflow: hidden;
  color: #606469;
  font-family: Nutmeg-Book;
  font-size: 14px;
  width: 65%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.templates-list__button-viewMore {
  background-color: white;
  color: #7fb91e;
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }

  &.disabled {
    color: gray;

    &:hover {
      cursor: default;
    }
  }
}

.form-view-header {
  display: flex;
  padding-left: 6rem;
  gap: 10px;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    padding-left: 0;
  }
  .form-view-header__title {
    width: 90%;
    @media only screen and (max-width: 480px) {
      width: 100%;
    }
    b {
      color: #606469;
    }
  }
  .form-view-header__btn-back {
    display: flex;
    justify-content: flex-end;
    width: 12%;
    @media only screen and (max-width: 480px) {
      justify-content: center;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.template--list__button-select {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 20px 75px;

  &:hover {
    cursor: pointer;
  }
}

.form-description {
  font-family: Nutmeg-Book;
  font-size: 12px;
  color: #606469;
}

.form-question-description {
  color: #606469;
  font-size: 0.7rem;
  margin-bottom: 0;
  font-family: Nutmeg-Book;
}

.form-question-label {
  font-family: Nutmeg-Regular;
  font-size: 0.7rem;
  color: #606469;
}

.form-question-label-cell {
  text-align: center !important;
  vertical-align: middle !important;
}

.form-question-row {
  background-color: #f1f3f4;
  border-bottom: 5px solid;
  border-color: white;
}

.form-question-file-label {
  background-color: #efefef;
  border-radius: 4px;
  border: 1px solid #606469;
  padding: 3px 5px;
  cursor: pointer;
}
#launcher-frame {
  bottom: 80px !important;
}

.help-desk__btn-directory {
  z-index: 2147483648;
  border-radius: 20px;
  background-color: #e93251;
  color: white;
  padding: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
    animation: 0.5s ease 0s 1 normal forwards running dewSlide;

    box-shadow: rgb(0/ 20%, 0/ 20%, 0/ 20%) 0px 2px 8px;
    transform: scale(1.04);
    transition: all 0.5s linear 0s;
    background: radial-gradient(circle, transparent 1%, #e93251 1%)
      center center / 15000% #e93251;
  }
  &.hidden {
    visibility: hidden;
  }
  i {
    color: white;
  }
}

.help-desk__directory-menu {
  .directory-menu__header {
    background-color: #e93251;
    height: 40px;
    color: white;
    display: grid;
    grid-template-columns: 95% 2fr;
    align-items: center;
    margin-top: -8px;
    padding-right: 10px;
    span {
      justify-self: center;
    }
    i {
      justify-self: flex-end;
      margin-right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .directory-menu__body {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 10px 25px 5px 20px;
    .time {
      display: flex;
      flex-direction: column;

      .subTitle {
        i {
          margin-right: 10px;
        }
      }

      .text {
        margin-left: 20px;
        font-family: Nutmeg-Book;
      }
    }
    .phones {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      .subTitle {
        i {
          margin-right: 10px;
        }
      }

      .text {
        margin-left: 20px;
        font-family: Nutmeg-Book;
      }
    }
  }
}

#searchSectionRecordView {
  @media (max-width: 480px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.policy-privacy-login {
  font-family: "Nutmeg-Book";
  color: #e93251;
  cursor: pointer;
  margin-top: 10px;

  a:link,
  a:visited {
    color: #e93251;
  }
}

.elec-records-file-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.procedure-validation {
  //background-color: #f2f8e8;
  background-color: rgba(213, 38, 79, 0.1);
  border-radius: 8px;
  padding: 15px 30px;

  .validation__buttons {
    display: flex;
    gap: 10px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    .btn-aceptar {
      background-color: #d5264f !important;
      color: white !important;
      border-radius: 20px !important;
      height: 32px;
      width: 184px;
      &.lang {
        height: 48px;
        @media (max-width: 768px) {
          width: 100%;
          height: 32px;
        }
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .btn-rechazar {
      background-color: rgba(0, 0, 0, 0.12) !important;
      color: rgba(0, 0, 0, 0.26) !important;
      height: 32px;
      width: 184px;
      border-radius: 20px !important;
      &.lang {
        height: 48px;

        @media (max-width: 768px) {
          width: 100%;
          height: 32px;
        }
      }
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}

.label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  right: 6px;
  bottom: 0px;
}

.label-shrinked {
  right: unset; //show complete label if shrinked
}

.color-primary {
  color: #c60b4c !important;
}

.post-content-link {
  a {
    color: #e93251;
  }
}

.qr-view {
  width: 100%;

  .qr-view-header {
    width: 100%;
    height: 50px;
    padding: 10px 5px;
    border-bottom: solid 1px rgb(156, 153, 153);
  }

  .qr-view-container {
    padding: 20px;

    .qr-view-title {
      font-size: 1.4em;
      font-family: "Nutmeg-Bold", serif;
      vertical-align: middle;
      color: #606469;
    }

    .field-qr {
      .field-qr-label {
        font-family: "Nutmeg-Regular";
        margin: 0px;
      }

      .field-qr-value {
        font-family: "Nutmeg-Book";
      }
    }
  }
}
.notification-detail {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .notification__date {
    span {
      color: #606469;
      font-family: "Nutmeg-Book";
    }
  }
  .notification__title {
    font-size: 16px;
  }
  .notification__description {
    color: #606469;
    font-family: "Nutmeg-Book";
    font-size: 14px;
    margin: 0px;
    pre {
      overflow-x: auto;
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
    }
  }
  .notification__responsible {
    span {
      color: #606469;
      font-family: "Nutmeg-Book";
    }
  }
}

.notifications-bell__title {
  font-family: Nutmeg-Regular, serif;
  font-weight: normal;
  font-size: 14px;
  color: #606469;
  line-height: 1.6;
}
.notifications-bell__text {
  color: #606469;
  font-size: 12px;
  font-family: Nutmeg-Book, serif;
  strong {
    font-family: Nutmeg-Regular, serif;
  }
}
.notifications-bell__date {
  font-family: Nutmeg-Book, serif;
  font-weight: normal;
  font-size: 11px;
  color: #989898;
  line-height: 1.6;
}

.link {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.notifications-list {
  @media (max-width: 480px) {
    .notifications-list__btn-new {
      order: 1;
      margin-bottom: 15px;
    }
    .notifications-list__tabs {
      order: 2;
    }
    .notifications-list__items {
      order: 3;
    }
  }
}
.filtersAreas {
  .filtersAreas__Areas {
    font-family: "Nutmeg-Regular";
    color: #606469;
    font-size: 16px;
  }
  .filtersAreas__SubAreas {
    font-family: "Nutmeg-Book";
    color: #606469;
    font-size: 16px;
  }
}

.formRender__question__fileExtension {
  color: #ffffff;
  width: 14px;
  height: 14px;
  background-color: red;
  margin-right: 6px;
  font-size: 9px;
  padding: 2px;
  display: inline;
}

//****** New Design - Mi Muro ******//

// -----------------------------------
// Background colors --- Start

.mi_muro-bg_dark {
  background-color: $mi_muro-dark;
}

.mi_muro-bg_gray {
  background-color: $mi_muro-gray !important;
}

.mi_muro-bg_gray-light {
  background-color: $mi_muro-gray-light;
}

.mi_muro-bg_magenta {
  background-color: $mi_muro-magenta !important;
}

.mi_muro-bg_cyan {
  background-color: $mi_muro-cyan;
}

.mi_muro-bg_yellow {
  background-color: $mi_muro-yellow;
}

.mi_muro-bg_blue {
  background-color: $mi_muro-blue;
}

.mi_muro-bg_green {
  background-color: $mi_muro-green;
}

// Background colors --- End
// -----------------------------------

// -----------------------------------
// Font colors --- Start

.mi_muro-color_dark {
  color: $mi_muro-dark;
}

.mi_muro-color_gray {
  color: $mi_muro-gray;
}

.mi_muro-color_gray-light {
  color: $mi_muro-gray-light;
}

.mi_muro-color_magenta {
  color: $mi_muro-magenta !important;
}

.mi_muro-color_cyan {
  color: $mi_muro-cyan !important;
}

.mi_muro-color_yellow {
  color: $mi_muro-yellow;
}

.mi_muro-color_blue {
  color: $mi_muro-blue !important;
}

.mi_muro-color_green {
  color: $mi_muro-green;
}

// Font colors --- End
// -----------------------------------

// -----------------------------------
// Font styles --- Start

h1.mi_muro {
  font-family: "Nutmeg-Bold";
  font-size: 34 * $mi_muro-font_size;
  line-height: 42 * $mi_muro-line-height;
}

h2.mi_muro {
  font-family: "Nutmeg-Bold";
  font-size: 34 * $mi_muro-font_size;
  line-height: 33 * $mi_muro-line-height;
}

h3.mi_muro {
  font-family: "Nutmeg-Regular";
  font-size: 24 * $mi_muro-font_size;
  line-height: 32 * $mi_muro-line-height;
}

h4.mi_muro {
  font-family: "Prompt-Regular", "Nutmeg-Regular";
  font-size: 22 * $mi_muro-font_size;
  line-height: 30 * $mi_muro-line-height;
}

h5.mi_muro {
  font-family: "Prompt-Regular", "Nutmeg-Regular";
  font-size: 20 * $mi_muro-font_size;
  line-height: 26 * $mi_muro-line-height;
}

p.mi_muro-p1 {
  font-family: "Prompt-Regular", "Nutmeg-Regular";
  // font-size: 20 * $mi_muro-font_size;
  line-height: 28 * $mi_muro-line-height;
}

p.mi_muro-p2 {
  font-family: "Prompt-Regular", "Nutmeg-Regular";
  // font-size: 18 * $mi_muro-font_size;
  line-height: 26 * $mi_muro-line-height;
}

// Font styles --- End
// -----------------------------------

// -----------------------------------
// Button styles --- Start

[class^="mi_muro-btn"],
[class*=" mi_muro-btn"] {
  font-family: "Prompt-SemiBold" !important;
  box-shadow: none !important;
  border-radius: 20px !important;

  .MuiButton-label {
    font-family: "Prompt-SemiBold" !important;
  }

  &.Mui-disabled {
    background-color: $mi_muro-btn_bg_disabled !important;
    color: $mi_muro-btn_color_disabled !important;
  }
}

.mi_muro-btn_magenta {
  background-color: $mi_muro-magenta !important;
  color: white !important;

  &.MuiButton-outlined,
  &.outlined {
    background-color: transparent !important;
    border: 1px solid $mi_muro-gray-light;
    color: $mi_muro-magenta !important;

    &.border-color {
      border-color: $mi_muro-magenta;
    }
  }
}

.mi_muro-btn_gray {
  background-color: $mi_muro-gray !important;
  color: white !important;

  &.MuiButton-outlined,
  &.outlined {
    background-color: transparent !important;
    color: $mi_muro-gray !important;

    &.border-color {
      border-color: $mi_muro-gray;
    }
  }
}

.mi_muro-btn_yellow {
  background-color: $mi_muro-yellow !important;
  color: black !important;

  &.MuiButton-outlined,
  &.outlined {
    background-color: transparent !important;
    color: $mi_muro-yellow !important;

    &.border-color {
      border-color: $mi_muro-yellow;
    }
  }
}

.mi_muro-btn_cyan {
  background-color: $mi_muro-cyan !important;
  color: white !important;

  &.MuiButton-outlined,
  &.outlined {
    background-color: transparent !important;
    color: $mi_muro-cyan !important;

    &.border-color {
      border-color: $mi_muro-cyan;
    }
  }
}

.pagination-button {
  background-color: $mi_muro-magenta !important;
}

// Button styles --- End
// -----------------------------------

// -----------------------------------
// Table styles --- Start

.mi_muro-table {
  padding: 5px;

  & > thead {
    background-color: $mi_muro-table_header_color;
    color: #fff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    th {
      color: #fff;
      font-family: "Prompt-SemiBold";
      font-size: 0.8rem !important;
    }

    // Border radius - Start
    tr th:nth-child(1) {
      border-top-left-radius: 20px;
    }
    tr th:nth-last-child(1) {
      border-top-right-radius: 20px;
    }
    // Border radius - End

    // Border color - Start
    tr th {
      border-bottom: none !important;
      border-right: 1px solid $mi_muro-table_border_color;
    }
    tr th:nth-last-child(1) {
      border-right: none;
    }
    // Border color - End
  }

  & > tbody {
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.4);
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;

    // Background color - Start
    tr:nth-child(2n) {
      background-color: #fff;
    }
    tr:nth-child(2n + 1) {
      background-color: $mi_muro-gray-light;
    }
    // Background color - End

    td {
      font-family: "Prompt-Regular", "Nutmeg-Book";
    }

    // Border radius - Start
    tr:nth-last-child(1) {
      td:nth-child(1) {
        border-bottom-left-radius: 20px;
      }

      td:nth-last-child(1) {
        border-bottom-right-radius: 20px;
      }
    }
    // Border radius - End

    // Border color - Start
    tr td {
      border-right: 1px solid $mi_muro-table_border_color;
      border-top: none !important;
      border-bottom: 1px solid $mi_muro-table_border_color;
    }
    tr td:nth-last-child(1) {
      border-right: none;
    }
    tr:nth-last-child(1) td {
      border-bottom: none;
    }
    // Border color - End
  }
}

// Table styles --- End
// -----------------------------------

// -----------------------------------
// Tab styles --- Start

.mi_muro-tabs {
  border-bottom: 1px solid #cccccc;

  button {
    color: $mi_muro-dark !important;
    font-family: Nutmeg-Bold;
  }

  .MuiTabs-indicator {
    background-color: $mi_muro-yellow;
    height: 4px !important;
  }
}

.card_bold_label {
  margin: 0px;
  color: #000;
  font-family: "Prompt-SemiBold";
}

.card_field_label {
  margin: 0px;
  font-size: 16px;
  display: flex;
  align-items: center;
  font-family: "Prompt-Regular";
}
// Tab styles --- End
// -----------------------------------

// -----------------------------------
// Cards Grid styles --- Start

.mi_muro-cards_grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .mi_muro-card {
    background-color: #fff;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.4);
    margin: 10px;
  }

  .mi_muro-card_header {
    min-height: 90px;
    padding: 10px 15px;

    &.header-small {
      min-height: 60px;
    }
  }


  .mi_muro-card_body {
    min-height: 100px;
    padding: 10px 15px;

    .mi_muro-card_field {
      width: 100%;
      margin: 10px 0px;

      .card_field_label {
        margin: 0px;
        color: #000;
        font-family: "Prompt-SemiBold";
      }

      .card_field_value {
        margin: 0px;
        font-size: 12px;
        display: flex;
        align-items: center;
        font-family: "Prompt-Regular";
      }
    }

    .mi_muro-card_accordion {
      padding: 0;
      // display: none;
      max-height: 0px;
      background-color: white;
      overflow: hidden;
      transition: max-height 1s linear 0s;

      &.accordion_open {
        max-height: 300px;
      }
    }
  }

  .mi_muro-card_footer {
    padding: 10px;
    display: flex;
    border-top: 1px solid rgba(124, 133, 140, 0.15);
    justify-content: flex-end;
  }
}

// Cards Grid styles --- End
// -----------------------------------

// -----------------------------------
// Chat styles --- Start

.mi_muro-chat {
  @media only screen and (min-width: 800px) {
    width: 30%;
    min-width: 300px;
    max-width: 400px;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }

  .modal-header {
    background-color: $mi_muro-chat_bg_color !important;
    padding: 0px;
    padding-left: 20px !important;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .chat-main-header {
    padding: 0px;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .chat-main-rounded .scrollbar {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .scrollbar {
    background-color: white;
  }

  .mi_muro-chat_bubble {
    width: 80%;
    margin-left: 0;
    border-radius: 10px;
    font-size: 12px !important;

    .mi_muro-chat_file_message,
    .mi_muro-chat_link_message {
      a {
        font-size: 12px !important;
        word-wrap: break-word;
      }

      a,
      a:link,
      a:visited,
      a:hover,
      a:active {
        color: $mi_muro-magenta !important;
      }
    }
  }

  .mi_muro-chat_bubble_received {
    margin-left: 10px;
    border-radius: 10px 10px 10px 0 !important;
  }

  .mi_muro-chat_bubble_sent {
    background-color: $mi_muro-chat_bg_color !important;
    border-radius: 10px 10px 0 10px !important;
  }

  .mi_muro-file_added {
    padding: 0px 5px;
    background-color: #fff;

    .CardFile {
      background-color: #e9ecef;
      border-radius: 5px 5px 0px 0px;
      padding: 5px;

      .File {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .Label {
          max-width: 90%;

          p {
            color: $mi_muro-magenta;
            font-size: 10px;
            margin: 0px;
            overflow-wrap: break-word;
          }
        }

        .Close {
          cursor: pointer;
          width: 20px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .chat-main-footer {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    .FormSection {
      width: 100%;

      .chat-textarea {
        background-color: #f1f3f4;
      }
    }

    .mi_muro-buttons_footer {
      width: 100%;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .AddFile {
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
  }
}

// Chat styles --- End
// -----------------------------------

// -----------------------------------
// Others styles --- Start

.mi_muro-card_view {
  background-color: #fff;
  border-radius: 10px;
  padding: 16px 20px;
}

.mi_muro-card_side {
  background-color: #fff;
  border-radius: 10px;

  .card_head {
    padding: 16px 20px;
    border-bottom: 2px solid $mi_muro-gray-light;

    .title {
      margin: 0px;
      font-family: "Prompt-Bold";
    }
  }

  .card_body {
    padding: 16px 20px;
  }
}

.mi_muro-accordion {
  border-radius: 20px !important;

  .accordion-title {
    font-family: "Prompt-Bold";
    font-size: 1rem;
    color: #404040;
  }
}

.mi_muro-list {
  width: 100%;

  .custom_br {
    width: 100%;
    height: 10px;
  }
}

.mi_muro-list_item {
  border: 1px solid $mi_muro-gray;
  border-radius: 10px;
  padding: 15px;

  &:not(:nth-last-of-type(1)) {
    margin-bottom: 10px;
  }

  .mi_muro-list_item-title {
    font-family: "Prompt-Bold";
    color: $mi_muro-cyan;
    font-size: 15px;
    margin: 0px;
    margin-bottom: 5px;
  }

  .mi_muro-list_item-text {
    font-family: "Nutmeg-Regular";
    color: $mi_muro-gray;
    font-size: 12px;
    margin: 0px;
  }

  .mi_muro-list_item-field {
    font-family: "Prompt-Regular";
    color: $mi_muro-gray;
    font-size: 12px;
    margin: 0px;

    .field_label {
      font-family: "Prompt-SemiBold";
      color: #000;
      margin-right: 5px;
      word-wrap: break-word;
    }

    .field_value {
      font-family: "Prompt-Regular";
      word-wrap: break-word;
    }
  }

  &.tracking_room_list {
    .mi_muro-list_item-tracking_data {
      width: 100%;
    }

    .mi_muro-list_item-tracking_btn {
      min-width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mi_muro-list_item-field {
      display: flex;
      flex-direction: column;

      .field_label {
        border-left: 5px solid $mi_muro-yellow;
        padding-left: 5px;
      }

      .field_value {
        color: black;
      }
    }
  }
}

.mi_muro-item-field {
  display: flex;
  flex-direction: column;
  font-family: "Prompt-Regular";
  color: $mi_muro-gray;
  font-size: 12px;
  margin: 0px;

  .field_label {
    font-family: "Prompt-SemiBold";
    color: #000;
    margin-right: 5px;
    border-left: 5px solid $mi_muro-yellow;
    padding-left: 5px;
  }

  .field_value {
    font-family: "Prompt-Regular";
    color: black;
    padding-left: 10px;
  }
}

.mi_muro-select {
  min-width: 120;
}

.mi_muro-select_btn {
  width: 100%;
  background-color: $mi_muro-yellow;
  border-radius: 20px;
  font-family: "Nutmeg-Regular" !important;
  font-size: 14px !important;
  padding-left: 10px;
  border: none;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.4);

  &.select-white {
    background-color: #fff;
    border: 1px solid $mi_muro-gray;
    box-shadow: none;
  }

  &.MuiInput-underline:before,
  &.MuiInput-underline:after {
    content: none;
  }
}

.mi_muro-select_btn_autocomplete {
  width: 100%;
  background-color: $mi_muro-yellow;
  border-radius: 20px;
  font-family: "Nutmeg-Regular" !important;
  font-size: 14px !important;
  padding-left: 10px;
  border: none;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.4);

  &.select-white {
    background-color: #fff;
    border: 1px solid $mi_muro-gray;
    box-shadow: none;
  }

  &.MuiInput-underline:before,
  &.MuiInput-underline:after {
    content: none;
  }
  &.MuiAutocomplete-root .MuiAutocomplete-inputRoot{
    // font-weight: bold !important;
    font-family: "Nutmeg-Regular" !important;
  font-size: 14px !important;
  }
}

.mi_muro-mail_btn {
  border-radius: 20px;
  width: 30px;
  height: 30px;
  background-color: $mi_muro-chat_bg_color;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.mail_btn-table {
    background-color: transparent;
  }

  &.pointed {
    padding-left: 10px;

    &::after {
      content: "●";
      font-size: 18px;
      color: red;
      position: relative;
      top: -10px;
      right: 5px;
    }
  }
}

.text-area-input_border {
  border-radius: 15px;

  &:focus {
    border: solid 1px $mi_muro-gray !important;
  }
}

li.sidenav__Multi {
  background-color: transparent !important;

  button {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff !important;
    color: #a1a1a1 !important;
  }
  button::before {
    background-color: #fff !important;
    color: #a1a1a1 !important;
    float: left !important;
    margin-right: 10px !important;
  }

  &.open > button {
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 0 !important;
  }

  &.open,
  &:hover,
  &.selected {
    button,
    button::before {
      background-color: transparent !important;
      color: $mi_muro-magenta !important;
    }

    ul:last-child {
      border-bottom-right-radius: 15px !important;
    }

    li:hover {
      background-color: transparent !important;

      .prepend-icon,
      .nav-text {
        background-color: transparent !important;
        color: $mi_muro-magenta !important;
      }
    }

    span {
      color: $mi_muro-magenta !important;
    }
  }

  .prepend-icon {
    background-color: transparent !important;
    padding-right: 0;

    span {
      color: #606469 !important;
    }

    &::after {
      color: #f9e5ed !important;
    }

    &:hover {
      background-color: transparent !important;

      span {
        color: $mi_muro-magenta !important;
      }
    }
  }
  
  .selected {
    span {
      color: #D5264F !important;
    }
  }

  ul.sub-menu {
    background-color: transparent !important;
    color: $mi_muro-magenta !important;
  }
}


li > .sidenav__Option {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  margin-left: 25px;
}

li > .sidenav__Option:hover,
li > .sidenav__Option.active {
  color: $mi_muro-magenta !important;
  background-color: transparent !important;

  span {
    color: $mi_muro-magenta !important;
  }
}

.expandIconProcedureView {
  color: #3fa7e1;
  background-color: rgba(63, 167, 225, 0.16);
  border-radius: 50%;
}
.mi_muro-checkbox_magenta {
  padding: 0 5px 0 0 !important;
  .MuiSvgIcon-root {
    font-size: 1.2rem !important;
    color: $mi_muro-magenta !important;
  }
}

.mi_muro-checkbox_cyan {
  padding: 0 5px 0 0 !important;
  .MuiSvgIcon-root {
    font-size: 1.2rem !important;
    color: $mi_muro-cyan !important;
  }
}

.MuiDialog-paperWidthSm {
  border-radius: 21px !important;
}

.mi_muro-bg_modal {
  background-color: $mi_muro-blue-modals;
}

.mi_muro-menu_action {
  span {
    color: #000;
  }
}

.mi_muro-menu_action:hover {
  background-color: $mi_muro-magenta-light !important;

  i,
  span {
    color: $mi_muro-magenta;
  }
}

.mi_muro-pagination {
  border-top: none !important;

  .button-pagination-number {
    font-size: 0.9rem !important;
    width: 20px;
    height: 20px;
  }

  .button-pagination-number:disabled {
    color: $mi_muro-cyan !important;
    background-color: $mi_muro-cyan-light !important;
  }
}

.mi_muro-members-group {
  display: flex;
  flex-direction: column;
  width: 100%;

  .member {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px;

    &:not(:nth-last-child(1)) {
      border-bottom: 2px solid #cccccc;
    }

    img {
      width: 35px;
      margin-right: 5px;
      border-radius: 50%;
    }

    .first-letter {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      margin-right: 5px;
      border-radius: 50%;
    }

    p {
      margin: 0px;
    }
  }
}

.modal-btn__submit {
  background-color: $mi_muro-blue-modals !important;
  color: #ffffff !important;
  border-radius: 21px !important;
}

.modal-btn__cancel {
  color: #7b868c !important;
  border-radius: 1px solid #7b868c !important;
  border-radius: 21px !important;
}

.mi_muro-scrollbar-custom_height {
  height: 100% !important;

  @media only screen and (max-width: 767px) {
    min-height: calc(100vh - 300px) !important;
  }
}

.mi_muro-title_section_header {
  @media (max-width: 500px) {
    font-size: 16px !important;
  }
}

.mi_muro-btn_back {
  display: flex;
  align-items: center;
  color: $mi_muro-gray-back_button;

  &:hover {
    color: $mi_muro-gray-back_button;
    text-decoration: none;
  }
}

.mi_muro-calendar {
  .rbc-month-view {
    border: none;
    border-radius: 20px;
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.4);

    .rbc-month-header {
      .rbc-header {
        background-color: #67737a;

        // Border radius - Start
        &:nth-child(1) {
          border-top-left-radius: 15px;
        }
        &:nth-last-child(1) {
          border-top-right-radius: 15px;
        }
        // Border radius - End
      }
    }

    .rbc-month-row {
      &:nth-last-child(1) {
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
      }
    }

    & > tbody {
      box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.4);
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;

      // Background color - Start
      tr:nth-child(2n) {
        background-color: #fff;
      }
      tr:nth-child(2n + 1) {
        background-color: $mi_muro-gray-light;
      }
      // Background color - End

      td {
        font-family: "Prompt-Regular", "Nutmeg-Book";
      }

      // Border radius - Start
      tr:nth-last-child(1) {
        td:nth-child(1) {
          border-bottom-left-radius: 20px;
        }

        td:nth-last-child(1) {
          border-bottom-right-radius: 20px;
        }
      }
      // Border radius - End

      // Border color - Start
      tr td {
        border-right: 1px solid $mi_muro-table_border_color;
        border-top: none !important;
        border-bottom: 1px solid $mi_muro-table_border_color;
      }
      tr td:nth-last-child(1) {
        border-right: none;
      }
      tr:nth-last-child(1) td {
        border-bottom: none;
      }
      // Border color - End
    }
  }
}

.surveys-date-input {
  background: #f1f3f4;
  width: fit-content;
  display: flex;
  align-items: center;
  font-family: Nutmeg-Book;
  font-size: 1rem;
  label {
    color: #d5264f;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
  }
  input {
    -webkit-appearance: media-slider !important;
    background: #f1f3f4;
    color: #606469;
  }
  input::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
  }
}

.mi_muro-field_label_multiline {
  @media (max-width: 635px) {
    margin-top: 22px;
  }
}

.mi_muro-field_label_multiline > label {
  @media (max-width: 635px) {
    top: -30%;
  }
}

.mi_muro-field_label_multiline-lg {
  @media (max-width: 927px) {
    margin-top: 22px;
  }
}

.mi_muro-field_label_multiline-lg > label {
  @media (max-width: 927px) {
    top: -30%;
  }
}

.mi_muro-form_check {
  .Mui-checked {
    color: $mi_muro-cyan !important;

    + .MuiSwitch-track {
      background-color: $mi_muro-cyan-light !important;
    }
  }
}

.mi_muro-form_maker_label {
  margin: 0px;
}

.mi_muro-form_maker_field {
  .MuiOutlinedInput-root {
    border-radius: 20px;

    &.Mui-disabled {
      border: 0.8px dotted;
    }
  }

  input {
    padding: 8px;
    font-family: unset !important;
  }
}

.mi_muro-form_maker-select {
  // background-color: $mi_muro-yellow;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 0px 10px;
  // border: none;

  &.MuiInput-underline:before,
  &.MuiInput-underline:after {
    content: none;
  }
}

.break-text-three-lines{
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical; 
}

.break-text-two-lines{
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical; 
}

.border-radius-20 {
  border-radius: 20px;
}

// Others styles --- End
// -----------------------------------
