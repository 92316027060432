/*Portfolio Styles*/

.portfolio-section {
  & .card-image {
    margin-bottom: 20px;
    & img {
      width: 100%;
      @include transition(all 1200ms ease);
    }
    &:hover {
      & img {
        @include box-shadow(0 2px 8px -2px rgba($black, 0.6));
        -webkit-transform: scale(1.08);
        -ms-transform: scale(1.08);
        transform: scale(1.08);
      }
    }
  }
}

.filter-with-bg-color {
  & ul {
    padding-left: 0;
    margin: 0 -10px 45px;
    & li {
      display: inline-block;
      margin-bottom: 10px;
      padding: 0 10px;
    }

    & li a,
    & li .jr-link {
      text-transform: uppercase;
      font-size: 12px;
      padding: 10px 24px;
      border: 1px solid $app-primary;
      display: block;
      text-decoration: none;
    }

    & li a:hover,
    & li a:focus,
    & li a.active,
    & li .jr-link:hover,
    & li .jr-link:focus,
    & li .jr-link.active {
      cursor: pointer;
      background-color: $app-primary;
      border-color: $app-primary;
      color: $white;
    }

    @media screen and (max-width: 575px) {
      margin-bottom: 15px;
    }
  }
}

.data-update-section {
  background-color: #f8f8f8 !important;
  & button {
    background-color: #c6004c !important;
    & span {
      font-family: "Nutmeg-Regular", serif !important;
      font-size: 15px !important;
    }
  }
  & button:disabled,
  & button[disabled] {
    color: #989898 !important;
    background-color: #cacaca !important;
  }
}

.data-update-content {
  padding-top: 10px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.profile-page-button {
  background-color: #c6004c !important;
}

.portfolio-form-title {
  font-size: 1.4em;
  font-family: Nutmeg-Bold, serif;
  vertical-align: middle;
  color: #606469;
}

.portfolio-form-subtitle {
  color: #989898 !important;
  font-size: 1rem !important;
}

.portfolio-form-card {
  padding: 5px 30px 20px !important;
  border: 1px solid #d3d3d3;
  border-radius: 15px;
  border-top-left-radius: 0px;
  &.complete {
    border-top-left-radius: 15px;
  }
  span {
    // color: #989898 !important;
  }
  p {
    // font-family: "Nutmeg-Book", serif !important;
    // color: #989898 !important;
    margin-bottom: unset !important;
  }
  label {
    // font-family: "Nutmeg-Regular", serif !important;
    font-size: 14px !important;
  }
  input {
    font-family: "Nutmeg-Regular", serif !important;
  }
  .MuiInputLabel-formControl {
    top: -9px !important;
  }

  @media screen and (max-width: 920px) {
    top: 0px !important;
  }
}

.expediente-viewer-list-item {
  border-left: solid 8px #C6004C;
  background-color: #F8F8F8;

  span {
    font-family: "Nutmeg-Book";
    color: #606469;

    b {
      font-family: "Nutmeg-Bold";
    }
  }
  
  .title {
    font-family: "Nutmeg-Regular";

    i {
      font-size: 1.2rem;
    }
  }
}