// Variables --- Start

$mi_muro-dark: #575757;
$mi_muro-gray: #7C858C;
$mi_muro-gray-light: rgba(124, 133, 140, .15);
$mi_muro-magenta: #D5264F;
$mi_muro-magenta-light: #f0c7d1;
$mi_muro-cyan: #2CA5DC;
$mi_muro-cyan-light: #dcebf1;
$mi_muro-yellow: #FECA1F;
$mi_muro-yellow-light: #f7edce;
$mi_muro-blue: #074792;
$mi_muro-green: #60AC41;
$mi_muro-blue-modals: #3FA7E1;
$mi_muro-gray-back_button: #7B868C;

$mi_muro-font_size: 1px;
$mi_muro-line-height: 1px;

$mi_muro-btn_bg_disabled: rgba(0, 0, 0, 0.12);
$mi_muro-btn_color_disabled: rgba(0, 0, 0, 0.26);

$mi_muro-table_header_color: #67737A;
$mi_muro-table_border_color: #aaacad;

$mi_muro-chat_bg_color: #d2e9f3;
